/**
 * author Liu Yin
 * date 2020/2/27
 * Description:路由管理
 */
import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../layout/main'
// eslint-disable-next-line no-unused-vars

Vue.use(Router)

export const mainRouter = {
  path: '/',
  component: Layout,
  redirect: '/home',
  children: [
    {
      path: 'home',
      name: 'home',
      component: () => import('../views/pc/main/home/index'),
      meta: {
        keepAlive: false,
        module: '首页'
      }
    },
    {
      path: 'policy',
      name: 'policy',
      component: () => import('../views/pc/main/policy/policy'), meta: {
        keepAlive: false,
        module: '资讯专栏'
      }

    },
    {
      path: 'train',
      name: 'train',
      component: () => import('../views/pc/main/train/index'), meta: {
        keepAlive: false,
        module: '培训服务'
      }
    },
    {
      path: 'recruitment',
      name: 'recruitment',
      component: () => import('../views/pc/main/recruitment/index'), meta: {
        keepAlive: false,
        module: '岗位推荐'
      }

    },

    // {
    //   path: 'wisdom',
    //   name: 'wisdom',
    //   component: () => import('../views/pc/main/wisdom/index'),
    //   meta: {
    //     keepAlive: false,
    //     module: '智慧人才库'
    //   }
    // },
    // {
    //   path: 'labour',
    //   name: 'labour',
    //   component: () => import('../views/pc/main/labour'),
    //   meta: {
    //     keepAlive: false,
    //     module: '人才管理'
    //   }
    // },
    {
      path: 'online',
      name: 'online',
      component: () => import('../views/pc/main/online'),
      meta: {
        keepAlive: false,
        module: '企业学院'
      }
    }, {
      path: 'talents',
      name: 'talents',
      component: () => import('../views/pc/main/talent'),
      meta: {
        keepAlive: false,
        module: '人才测评'
      }
    },
    {
      path: 'cloudservices',
      name: 'cloudservices',
      component: () => import('../views/pc/main/cloudservices/index'), meta: {
        keepAlive: false,
        module: '合作伙伴'
      }
    },
    {
      path: 'archive',
      name: 'archive',
      component: () => import('../views/pc/main/archive/index'),
      meta: {
        keepAlive: false,
        module: '资料库'
      }
    }
    // {
    //   path: 'information-material',
    //   name: 'information-material',
    //   component: () => import('../views/pc/main/archive/index'),
    //   meta: {
    //     keepAlive: false,
    //     module: '材料资讯'
    //   }
    // },
    // {
    //   path: 'scientific',
    //   name: 'scientific',
    //   component: () => import('../views/pc/main/archive/index'),
    //   meta: {
    //     keepAlive: false,
    //     module: '科研信息'
    //   }
    // },
    // {
    //   path: 'student-practical',
    //   name: 'student-practical',
    //   component: () => import('../views/pc/main/archive/index'),
    //   meta: {
    //     keepAlive: false,
    //     module: '学生实训'
    //   }
    // },
    // {
    //   path: 'information-notice',
    //   name: 'information-notice',
    //   component: () => import('../views/pc/main/archive/index'),
    //   meta: {
    //     keepAlive: false,
    //     module: '通知信息'
    //   }
    // }
    // {
    //   path: 'PolicyDetail',
    //   name: 'PolicyDetail',
    //   component: () => import('../views/pc/main/policy/PolicyDetail'),
    //   meta: {
    //     keepAlive: false,
    //     module: '资讯详情'
    //   }
    // }
    // {
    //   path: 'resource',
    //   name: 'resource',
    //   component: () => import('../views/pc/main/resource/resourceModel'),
    //   meta: {
    //     keepAlive: false,
    //     module: '资源共享'
    //   }
    // }, {
    //   path: 'project',
    //   name: 'project',
    //   component: () => import('../views/pc/main/project'),
    //   meta: {
    //     keepAlive: false,
    //     module: '合作商机'
    //   }
    // }
    // {
    //   path: 'recommend',
    //   name: 'project',
    //   component: () => import('../views/pc/main/wisdom/recommend'),
    //   meta: {
    //     keepAlive: false,
    //     module: '人才推荐'
    //   }
    // }
    // {
    //   path: 'resume',
    //   name: 'resume',
    //   component: () => import('../views/pc/main/wisdom/resume'),
    //   meta: {
    //     keepAlive: false,
    //     module: '智慧人才库'
    //   }
    // }
  ]
}

export const constantRoutes = [

  {
    path: '/login',
    component: () => import('../views/pc/login')
  },
  {
    path: '/login/loginEnterprise',
    component: () => import('../views/pc/login/loginEnterprise')
  },
  {
    path: '/register',
    component: () => import('../views/pc/register')
  },
  {
    path: '/project-d/',
    component: Layout,
    redirect: '/project-d/details',
    children: [
      {
        path: 'details',
        component: () => import('../views/pc/main/project/details'),
        meta: {
          module: '公司详情简介'
        }
      }
    ]
  },
  {
    path: '/talent-m',
    component: Layout,
    redirect: '/talent-m/index',
    children: [
      {
        path: 'appraise',
        name: 'appraise',
        component: () => import('../views/pc/main/talent/index'),
        meta: {
          keepAlive: false,
          module: '人才测评'
        }
      }
      // redirect: '/talent-m/appraise',
      // children: [
      //   {
      //     path: 'appraise',
      //     name: 'appraise',
      //     component: () => import('../views/pc/main/talent/appraise'),
      //     meta: {
      //       keepAlive: false,
      //       module: '人才测评'
      //     }
      //   }
      /* {
        path: 'appraise-introduce',
        name: 'appraise-introduce',
        component: () => import('../views/pc/main/talent/appraiseIntroduce'),
        meta: {
          module: '人才测评'
        }
      },
      {
        path: 'do-appraisal',
        name: 'do-appraisal',
        component: () => import('../views/pc/main/talent/doAppraisal'),
        meta: {
          module: '人才测评'
        }
      }*/
    ]
  },
  {
    path: '/wisdom-m',
    component: Layout,
    redirect: '/wisdom-m/wisdomPool',
    children: [
      {
        path: 'wisdomPool',
        component: () => import('../views/pc/main/wisdom/pool'),
        meta: {
          module: '智慧人才库'
        }
      }
    ]
  },
  {
    path: '/online-m',
    component: Layout,
    redirect: '/online-m/course',
    children: [
      {
        path: 'course',
        component: () => import('../views/pc/main/online/course'),
        meta: {
          keepAlive: false,
          module: '企业学院'
        }
      }
    ]
  },
  {
    path: '/policy-m',
    component: Layout,
    redirect: '/policy-m/preview',
    children: [
      {
        path: 'preview',
        component: () => import('../views/pc/main/policy/policyDetail'),
        meta: {
          keepAlive: false,
          module: '资讯专栏'
        }
      }
    ]
  },
  {
    path: '/resource-m',
    component: Layout,
    redirect: '/resource-m/list',
    children: [
      {
        path: 'list',
        component: () => import('../views/pc/main/resource/resourceModel'),
        meta: {
          keepAlive: false,
          module: '资源共享'
        }
      }
    ]
  },
  mainRouter
]
const createRouter = () => new Router({
  // 如果需要多入口,将mode和base去掉
  mode: 'history',
  // base: '/portal/',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
const router = createRouter()

export default router
